import axios, { AxiosRequestConfig } from 'axios'
import { env } from 'src/utils/env'

export const getSiaranPers = async (config?: AxiosRequestConfig) => {
  return await axios.get(`${env.BASE_MAGNOLIA_REST_URL}/delivery/siaran-pers`, {
    ...config,
    params: { ...config.params, orderBy: 'publish desc' }
  })
}

export const getSiaranPersDetail = async (slug: string) => {
  return await axios.get(`${env.BASE_MAGNOLIA_REST_URL}/delivery/siaran-pers/${slug}`)
}
